






import { Component, Vue } from "vue-property-decorator";
import AdminShopDealTable from "@/components/admin/shop/deal/AdminShopDealTable.vue";

/**
 * AdminShopDealManagement view
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        AdminShopDealTable,
    },
})
export default class AdminShopDealManagement extends Vue {}
