
























































import { Component, Prop, Vue } from "vue-property-decorator";
import AlertModule, { AlertType } from "@/store/modules/alert";
import axios, { APIResponse } from "@/plugins/axios";
import { Deal } from "@/types/shop/article";
import { DataTableHeader } from "vuetify/types/";
import AdminBaseItemTable from "@/components/admin/base/item-management/AdminBaseItemTable.vue";
import AdminLangCodeDescriptionTable from "@/components/admin/language/descripton/AdminLangCodeDescriptionTable.vue";
import AdminShopDealArticleTable from "./AdminShopDealArticleTable.vue";

/**
 * AdminShopArticleTable component
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        AdminBaseItemTable,
        AdminLangCodeDescriptionTable,
        AdminShopDealArticleTable,
    },
})
export default class AdminShopDealTable extends Vue {
    @Prop({ type: Boolean, default: false }) readonly editable!: boolean;

    private deals: Deal[] = [];

    private defaultDealItem: Deal = {
        id: -1,
        descriptions: [],
        startTime: "",
        endTime: "",
        articles: [],
    };

    /**
     * Function will be automatic called by Vue.js (see vue lifecycle)
     * Function fetches data, initialize variables etc.
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async created() {
        try {
            this.deals = await this.fetchDeals();
        } catch (err) {
            const errorMessage =
                err instanceof Error ? err.message : (err as string);

            AlertModule.showAlert({
                type: AlertType.ERROR,
                message: errorMessage,
            });
        }
    }

    /**
     * @returns table headers
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private get tableHeaders(): DataTableHeader[] {
        return [
            {
                text: this.$tc("language.description").toString(),
                value: "descriptions",
            },
            {
                text: this.$t("shop.deal.startTime").toString(),
                value: "startTime",
            },
            {
                text: this.$t("shop.deal.endTime").toString(),
                value: "endTime",
            },
        ];
    }

    /**
     * Fetches all deals
     *
     * @returns Promise<Deal[]>
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async fetchDeals(): Promise<Deal[]> {
        const response = (
            await axios.get<APIResponse<Deal[]>>("/admin/article/deals")
        ).data;
        if (response.status === "error") {
            throw new Error(response.message || "unknownError");
        }

        return response.data;
    }
}
