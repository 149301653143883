









































import { Component, Prop, Vue } from "vue-property-decorator";
import AlertModule, { AlertType } from "@/store/modules/alert";
import axios, { APIResponse } from "@/plugins/axios";
import { Article, DealArticle } from "@/types/shop/article";
import { DataTableHeader } from "vuetify/types/";
import AdminBaseItemTable from "@/components/admin/base/item-management/AdminBaseItemTable.vue";
import AdminShopArticlePriceTable from "@/components/admin/shop/article/price/AdminShopArticlePriceTable.vue";

/**
 * AdminShopDealArticleTable component
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        AdminBaseItemTable,
        AdminShopArticlePriceTable,
    },
})
export default class AdminShopDealArticleTable extends Vue {
    @Prop({ type: Boolean, default: false }) readonly editable!: boolean;
    @Prop({ type: Array, required: true })
    readonly dealArticles!: DealArticle[];

    private articles: Article[] = [];

    private defaultDealArticleItem: DealArticle = {
        article: {
            id: -1,
            number: "",
            sellCount: 0,
            createDate: new Date().toLocaleString("de"),
            groups: [],
            producer: {
                id: -1,
                name: "",
                homepage: "",
                description: "",
                image: {
                    id: -1,
                    src: "",
                    alt: "",
                },
            },
            descriptions: [],
            images: [],
            isStock: false,
            stock: 0,
            deliveryTime: 0,
            reviews: [],
            prices: [],
            properties: [],
            deals: [],
        },
        prices: [],
    };

    /**
     * Function will be automatic called by Vue.js (see vue lifecycle)
     * Function fetches data, initialize variables etc.
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async created() {
        try {
            this.articles = await this.fetchArticles();
        } catch (err) {
            const errorMessage =
                err instanceof Error ? err.message : (err as string);

            AlertModule.showAlert({
                type: AlertType.ERROR,
                message: errorMessage,
            });
        }
    }

    /**
     * @returns table headers
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private get tableHeaders(): DataTableHeader[] {
        return [
            {
                text: this.$t("shop.article.number").toString(),
                value: "article.number",
            },
        ];
    }

    /**
     * Fetches all articles
     *
     * @returns Promise<Article[]>
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async fetchArticles(): Promise<Article[]> {
        const response = (await axios.get<APIResponse<Article[]>>("/articles"))
            .data;
        if (response.status === "error") {
            throw new Error(response.message || "unknownError");
        }

        return response.data;
    }
}
