var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AdminBaseItemTable',{attrs:{"title":_vm.$t('shop.article'),"items":_vm.dealArticles.map(function (item, key) { return (Object.assign({}, item, {key: key})); }),"item-key":"key","headers":_vm.tableHeaders,"editable":_vm.editable,"defaultCRUDItem":_vm.defaultDealArticleItem},on:{"crudDialogSave":function (updatedItems, crudDialogMode, crudItem, crudItemIndex) { return _vm.$emit(
                'crudDialogSave',
                updatedItems,
                crudDialogMode,
                crudItem,
                crudItemIndex
            ); }},scopedSlots:_vm._u([{key:"crud-form",fn:function(ref){
            var item = ref.item;
return [_c('v-select',{attrs:{"label":_vm.$t('shop.article'),"items":_vm.articles,"item-text":"number","item-value":function (article) { return article; },"rules":[function (v) { return !!v; }],"required":""},model:{value:(item.article),callback:function ($$v) {_vm.$set(item, "article", $$v)},expression:"item.article"}}),_c('AdminShopArticlePriceTable',{attrs:{"editable":"","articlePrices":item.prices},on:{"crudDialogSave":function (updatedPrices) { return (item.prices = updatedPrices); }}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }